import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  onClose,
  handleSubmit,
  setAminityLoading,
  amenityId,
  setForceEdit,
  setShow,
  initialValues,
  setValues
}) => {
  const handleProceed = async () => {
    try {
      if (amenityId > 0) {
        setShow(false);
        setForceEdit(true);
        handleSubmit();
      }
    } catch (error) {
      console.error("Error in handleProceed:", error);
      setAminityLoading(false);
    }
  };

  return (
    <Modal size="m" centered show={show} onHide={()=>{onClose();setValues(initialValues)}}>
      <Modal.Header className="modal-header px-4 py-4" closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <p>
          This amenity is being actively used in other Rooms/Villas. Editing or
          deleting it would impact them. Are you sure you want to proceed?
        </p>
      </Modal.Body>
      <Modal.Footer className="px-4">
        <button
        className="btn btn-secondary"
          type="button"
          variant="secondary"
          onClick={() => {
            onClose();
            setValues(initialValues);
          }}
        >
          Cancel
        </button>
        <Button type="submit" className="btn btn-primary" variant="primary" onClick={handleProceed}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
