import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dummyImage from "../../assets/images/album.png";

const DragAbleImage = ({ maincroppedImage, index, videoImg, handleRemoveImage = "" }) => {
  const [isRemoveActive, setIsRemoveActive] = useState(false); // To track remove button interaction
  const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({
    id: index.toString(),
    disabled: isRemoveActive, // Disable dragging when interacting with the button
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1, // Fade effect during dragging
  };

  // Handle right-click to open the image in a new tab
  const handleImageRightClick = (e) => {
    e.preventDefault(); // Prevent the default right-click menu
    window.open(maincroppedImage, "_blank", "noopener noreferrer"); // Open image in new tab
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...(!isRemoveActive && listeners)} // Attach drag listeners only when not hovering remove button
      style={style}
      className="property-img uploaded d-flex justify-content-center align-items-center position-relative"
    >
      <div className="upload-img position-relative">
        {/* Image */}
        <img
          src={maincroppedImage.endsWith(".mp4") ? videoImg : maincroppedImage}
          alt="Uploaded"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = dummyImage; // Use fallback image if error occurs
          }}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            pointerEvents: isDragging ? "none" : "auto", // Prevent click during dragging
          }}
          onContextMenu={handleImageRightClick} // Handle right-click event
        />

        {/* Remove button */}
        <button
          className="remove-image-button position-absolute end-0 top-0"
          onMouseEnter={() => setIsRemoveActive(true)} // Show remove button on hover
          onMouseLeave={() => setIsRemoveActive(false)} // Hide remove button on hover out
          onClick={(e) => {
            e.stopPropagation(); // Prevent drag from being triggered
            handleRemoveImage(e, index); // Trigger remove function
          }}
          style={{
            zIndex: 10, // Ensure button is on top
            cursor: "pointer",
          }}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    </div>
  );
};

export default DragAbleImage;
