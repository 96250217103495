import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FaCalendarAlt } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import moment from "moment";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";

const Table1 = ({
  columns,
  data,
  pageNumber = 0,
  resortsData,
  isShowingHeading = "",
  handleDownloadExcelFile = "",
  headerHeading = "",
  isExcelDownloadBtnShow = "",
  setStart = "",
  setEndDate = "",
  filterStartDate = "",
  filterEndDate = "",
  showDateRangePickerFieldInHeading = ""
}) => {
  const [isListView, setIsListView] = useState(true);
  const [filteredData, setFilteredData] = useState();

  const toggleView = () => {
    setIsListView(true);
  };
  const toggleGridView = () => {
    setIsListView(false);
  };
  const filterTypes = React.useMemo(() => ({
    text: (rows, id, filterValue) => {
      console.log("Filtering on:", id, "with value:", filterValue); // Debug log
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
          : true;
      });
    },
  }), []);

  //const data1 = filteredData ? filteredData : data;
  const initialPageSize = 20;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { globalFilter },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber);
  // Handle pagination controls
  const handleGoToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    gotoPage(pageIndex);
  };

  const handleNextPage = () => {
    setCurrentPageIndex(pageIndex + 1);
    nextPage();
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(pageIndex - 1);
    previousPage();
  };

  // const setdatafilter = (e) => {
  //   setGlobalFilter(e);

  //   var i = 1;
  // };

  // Flag to check if user has interacted with the picker
  const [isPickerFocused, setIsPickerFocused] = useState(false);

  // Handler for when the user applies the date range

  // const handleApply = (event, picker) => {
  //   if (isPickerFocused) {
  //     setStart(new Date(picker.startDate));
  //     setEndDate(new Date(picker.endDate));
  //   }
  // };
  const [newDate, setNewDate] = useState(new Date()); // Manage maxDate as state
  const [tempStartDate, setTempStartDate] = useState(null); // Temporary start date
  const [tempEndDate, setTempEndDate] = useState(null);
  // const handleClear = (e) => {
  //   e.preventDefault();
  //   // Manually clear the selected dates if the picker has a "Clear" button    
  //   setStart(null);
  //   setEndDate(null);
  //   setNewDate(null)
  // };

  const handleClear = (e) => {
    e.preventDefault();
    setStart(null);         // Clear the applied start date
    setEndDate(null);       // Clear the applied end date
    setTempStartDate(null); // Clear any preview state
    setTempEndDate(null);
  };

  const handleApply = (event, picker) => {
    const start = new Date(picker.startDate);
    const end = new Date(picker.endDate);

    setTempStartDate(start); // Update preview state
    setTempEndDate(end);
    setStart(start);         // Set the applied dates
    setEndDate(end);
  };

  const handleShowPreview = (picker) => {
    setTempStartDate(new Date(picker.startDate)); // Show selected dates in preview
    setTempEndDate(new Date(picker.endDate));
  };

  // Handle focus on input
  const handleFocus = () => {
    setIsPickerFocused(true);
  };

  // Handle blur (clicking outside)
  const handleBlur = () => {
    setIsPickerFocused(false);
  };
  const handleCancel = () => {
    setStart(null);  // Clear start date
    setEndDate(null);    // Clear end date
  };
  console.log("newDate", newDate)
  console.log("filterStartDate && filterEndDate", filterStartDate, filterEndDate)
  return (
    <div className="card-body ">
      <div id="list-grid-view" className="tabledata list-view-filter">
        {isShowingHeading && (
          <>
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">{headerHeading}</h5>

              <div className="right-table-actions d-flex">

                <div className="filter-buttons d-flex align-items-center ">
                  <form action="#" method="get">
                    <div className="position-relative">
                      <input
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                        type="text"
                        className="form-control rounded-1 py-2"
                        placeholder="Type to Search..."
                      />
                    </div>

                  </form>
                </div>
                <div className="ms-4">
                  {showDateRangePickerFieldInHeading && (
                    <DateRangePicker

                      initialSettings={{
                        opens: "center",
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                        minDate: false,              // Allow any start date
                        maxDate: newDate,         // Max end date is today
                        autoApply: false,            // No auto-apply, user selects manually
                        clearButton: true,
                        //  showDropdowns: true, 
                        onCancel: (e) => handleClear(e),  // Call the custom cancel handler

                      }}
                      onApply={(event, picker) => {
                        // When the user clicks Apply, set the selected dates
                        setStart(new Date(picker.startDate));
                        setEndDate(new Date(picker.endDate));
                        // Optionally, apply the filter here if needed
                        //  applyFilter(picker.startDate, picker.endDate);
                      }}
                      onShow={(event, picker) => handleShowPreview(picker)}
                      // onApply={handleApply}
                      // onCancel={(e) => handleClear(e)}                     
                      onCancel={(event, picker) => {
                        handleClear(event); // Clear selected dates
                        picker.setStartDate(new Date); // Reset the picker's internal state for start date
                        picker.setEndDate(new Date); // Reset the picker's internal state for end date
                      }}
                    // Reset date range on cancel
                    //  onClose={handleCancel}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control py-2"
                          placeholder="Select date range"  // Display placeholder when no date is selected
                          value={
                            filterStartDate && filterEndDate
                              ? `${moment(filterStartDate).format("DD/MM/YYYY")} - ${moment(filterEndDate).format("DD/MM/YYYY")}`
                              : ""  // Show empty value until Apply is pressed
                          }
                          readOnly
                        // onFocus={handleFocus}   // Set focus flag when the input field is focused
                        // onBlur={handleBlur}
                        />
                        <div className="input-group-append py-0" >
                          <span className="input-group-text py-2">
                            <FaCalendarAlt className="" style={{ height: "25px" }} /> {/* Calendar icon */}
                          </span>
                        </div>
                      </div>
                    </DateRangePicker>
                  )}

                </div>
                {isExcelDownloadBtnShow && (
                  <button
                    onClick={handleDownloadExcelFile}
                    className="btn ms-2 btn-theme newuseradd-btn"
                  // style={{ backgroundColor: "#1f2f3e" }}
                  >
                    {/* <FaDownload className="mr-2" /> */}
                    <RiFileExcel2Line className="text-white" />
                    {/* <FaDownload  className="text-white"/> */}
                    <span className="text-white"> Download</span>
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        <div className="list-offers">
          {/* <LeadTable tableName={"List of Customers"} columns={columns} data={data} /> */}
          <div className="table-responsive">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        style={{ width: column.width }}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} />
                            )
                          ) : (
                            // <FontAwesomeIcon icon={faSort} />
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => (
                        <td {...cell.getCellProps()}>
                          {index === 0 ? row.index + 1 : cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center flex-wrap justify-content-between mt-4 mb-3 px-md-0 px-3">
            <div className="ps-md-3 show-enteries">
              <label
                className="d-flex align-items-center"
                style={{ maxWidth: "190px" }}
              >
                Show{" "}
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setCurrentPageIndex(0); // Reset to the first page when changing rows per page
                    setPageSize(Number(e.target.value));
                  }}
                  className="form-select ms-3 me-2"
                >
                  {[20, 40, 60, 80, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>{" "}
                entries
              </label>
            </div>
            <div className="atmosphere-pagination album-pagination pe-md-3 pe-0 d-flex justify-content-md-end">
              <div className="pagi-wrap d-flex align-items-center justify-content-between flex-wrap">
                <div className="pagination-info pe-3">
                  Showing {pageIndex * pageSize + 1} to{" "}
                  {Math.min((pageIndex + 1) * pageSize, rows.length)} of{" "}
                  {rows.length} entries
                </div>
                <div className="atmosphere-pagination mt-0">
                  <ul className="pagination m-0 d-flex justify-content-end">
                    <li className="page-item">
                      <button
                        onClick={() => handlePreviousPage()}
                        disabled={!canPreviousPage}
                        className="page-link d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-angle-left"></i>
                        {/* <i className="fa-solid fa-angle-left"></i> */}
                      </button>
                    </li>
                    <Pagination
                      currentPage={currentPageIndex}
                      handleGoToPage={handleGoToPage}
                      pageCount={pageCount}
                    />

                    <li className="page-item">
                      <button
                        className="page-link d-flex align-items-center justify-content-center"
                        onClick={() => handleNextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>

                        {/* <i className="fa-solid fa-angle-right"></i> */}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table1;





const Pagination = ({ pageCount, currentPage, handleGoToPage }) => {
  const maxPagesToShow = 5; // Number of page numbers to display
  const half = Math.floor(maxPagesToShow / 2);

  let startPage = Math.max(0, currentPage - half);
  let endPage = Math.min(pageCount - 1, currentPage + half);

  // Adjust the start and end pages if at the edges
  if (currentPage < half) {
    endPage = Math.min(maxPagesToShow - 1, pageCount - 1);
  } else if (currentPage > pageCount - 1 - half) {
    startPage = Math.max(pageCount - maxPagesToShow, 0);
  }

  return (
    < >
      {startPage > 0 && (
        <>
          <li className="page-item">
            <button className="page-link" onClick={() => handleGoToPage(0)}>
              First
            </button>
          </li>
          {startPage > 1 && (
            <li className="page-item">
              <button disabled={true}>
                ...
              </button>
            </li>
          )}
        </>
      )}

      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <li className="page-item" key={startPage + index}>
          <button
            className={`page-link ${currentPage === startPage + index ? "active" : ""}`}
            onClick={() => handleGoToPage(startPage + index)}
          >
            {startPage + index + 1}
          </button>
        </li>
      ))}

      {endPage < pageCount - 1 && (
        <>
          {endPage < pageCount - 2 && (
            <li className="page-item">
              <button disabled={true}>
                ...
              </button>
            </li>
          )}
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => handleGoToPage(pageCount - 1)}
            >
              Last
            </button>
          </li>
        </>
      )}
    </>
  );
};
