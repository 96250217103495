import React, { useState } from "react";
import Table from "../../components/Table2";
import { getEventEnquiryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../globalContext";
import { useContext } from "react";
import CSVFileDownload from "../../components/CSVFileDownload";
const EventEnquiry = () => {
    const { bwebsiteId } = useContext(GlobalContext);

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showMessages, setMessages] = useState("");

    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");

    const allEventEnqueryData = useQuery(
        ["getEventEnquiryData", +websiteIdFromCookie],
        () => getEventEnquiryData(websiteIdFromCookie)
    );
    const currentDateTime = new Date();
    const [filterStartDate, setStart] = useState(null); // Initially no date selected
    const [filterEndDate, setEnd] = useState(null);

    // Filter data based on date range

  
    const filteredData =
        filterStartDate && filterEndDate
            ? allEventEnqueryData?.data?.data?.filter((item) => {
                const itemDate = new Date(item.created_on);
                return itemDate >= filterStartDate && itemDate <= filterEndDate;
            })
            : allEventEnqueryData?.data?.data; // No filter applied, show all data
    const ExcelFileDownloadData = filteredData || allEventEnqueryData?.data?.data;



    const [excelDownload, setDownload] = useState();

    const handleDownloadExcelFile = (downloadExcel) => {
        setDownload(downloadExcel);
    };


    const handleViewMessageModal = (message) => {
        setMessages(message);

        setShowMessageModal(true);
    };
    const closeMessageModal = () => setShowMessageModal(false);

    let columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },

            {
                Header: "Name",
                accessor: "name",
                Cell: ({ row }) => `${row.original.name}`,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ row }) => `${row.original.email}`,
            },
            {
                Header: "Phone Number",
                accessor: "phone_no",
                Cell: ({ row }) => `${row.original.phone_no}`,
            },

            {
                Header: "Purpose of Event",
                accessor: "event_purpose",
                Cell: ({ row }) => `${row.original.event_purpose === null ? "" : row.original.event_purpose}`,
            },
            {
                Header: "Venue Type",
                accessor: "event_type",
                Cell: ({ row }) => `${row.original.event_type === null ? "" : row.original.event_type}`,
            },

            {
                Header: "Company Name",
                accessor: "company_name",
                Cell: ({ row }) => `${row.original.company_name === null ? "" : row.original.company_name}`,
            },
            {
                Header: "Guest Rooms",
                accessor: "guest_room",
                Cell: ({ row }) => `${row.original.guest_room === null ? "" : row.original.guest_room}`,
            },
            {
                Header: "Attendees",
                accessor: "attendees",
                Cell: ({ row }) => `${row.original.attendees === null ? "" : row.original.attendees}`,
            },
            {
                Header: "Event Start Date",
                accessor: "event_start_date",
                Cell: ({ row }) =>
                    moment(row.original.event_start_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            },
            {
                Header: "Event End Date",
                accessor: "event_end_date",
                Cell: ({ row }) =>
                    moment(row.original.event_end_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            },
            {
                Header: "Created on",
                accessor: "created_on",
                Cell: ({ row }) => row.original.created_on === null ? "" :
                    moment(row.original.created_on).format("MMM D, YYYY h:mm"),
            },

        ],
        []
    );



    const getFileName = () => {
        if (filterStartDate && filterEndDate) {
            // If filtered, use the start and end date
            return `Kolkata Event Enquiries_${moment(filterStartDate).format("DD/MM/YYYY")} - ${moment(filterEndDate).format("DD/MM/YYYY")}.xlsx`;
        } else if (allEventEnqueryData?.data?.data?.length) {
            // If not filtered, use the first and last created_on dates from the data
            const firstDate = allEventEnqueryData?.data?.data[0]?.created_on;
            const lastDate = allEventEnqueryData?.data?.data[allEventEnqueryData?.data?.data?.length - 1]?.created_on;

            return `Kolkata Event Enquiries_${firstDate ? moment(firstDate).format("DD/MM/YYYY") : ""} - ${lastDate ? moment(lastDate).format("DD/MM/YYYY") : "25_11_2024"}.xlsx`;
        }
        return "Kolkata Event Enquiries.xlsx"; // Fallback in case no data is available
    };
    const headerHeading = "All Event Enquiries";
    let headersForExcelFile = [
        "Sr. No",
        "Name",
        "Email",
        "Phone",
        "Purpose of Event",
        "Venue Type",
        "Company Name",
        "Guest Rooms",
        "Attendees",
        "Event Start Date",
        "Event End Date",
        "Created On"
    ];

    const excelFileData = ExcelFileDownloadData?.map((item, index) => {
        const row = [
            index + 1, // Sr. No
            item.name, // Name
            item.email, // Email
            item.phone_no, // Phone
            item.event_purpose,
            item.event_type,
            item.company_name,
            item.guest_room,
            item.attendees,

        ];

        row.push(
            item.event_start_date ? moment(item.event_start_date).format("DD-MM-YYYY HH:mm:ss") : "",
            item.event_end_date ? moment(item.event_end_date).format("DD-MM-YYYY HH:mm:ss") : "",
            item.created_on ? moment(item.created_on).format("DD-MM-YYYY HH:mm:ss") : ""
        );
        return row;
    });

    if (allEventEnqueryData.status === "loading") {
        <div>Loading</div>;
    }

    if (!allEventEnqueryData.data) {
        <div>Loading</div>;
    }
    if (allEventEnqueryData.isLoading) {
        <div>Loading</div>;
    }

    return (
        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-header">
                            <h4 className="fw-normal m-0">Event Enquiries</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5 mt-4">
                        {/* <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All Enquiries</h5>
                        </div> */}
                        {allEventEnqueryData?.data && (
                            <CSVFileDownload
                                data={excelFileData}
                                headers={headersForExcelFile}
                                filename={getFileName()}
                                // filename={`Kolkata Event Enquiries_${filterStartDate?moment(filterStartDate).format("DD/MM/YYYY"):""} - ${ filterEndDate?moment(filterEndDate).format("DD/MM/YYYY"):""}.xlsx`}
                                excelDownload={excelDownload}
                                onClick={handleDownloadExcelFile}
                            />
                        )}
                        {allEventEnqueryData?.data &&
                            (allEventEnqueryData.isLoading || !allEventEnqueryData?.data ? (
                                <div className="text-center">Loading...</div>
                            ) : allEventEnqueryData?.data?.data?.length === 0 ? (
                                <div className="card-body text-center">No Data Available</div>
                            ) : (
                                <Table
                                    data={ExcelFileDownloadData}
                                    key={allEventEnqueryData?.data?.data.enquiry_id}
                                    columns={columns}
                                    isShowingHeading={true}
                                    isExcelDownloadBtnShow={true}
                                    headerHeading={headerHeading}
                                    handleDownloadExcelFile={handleDownloadExcelFile}
                                    setStart={setStart}
                                    setEndDate={setEnd}
                                    filterStartDate={filterStartDate}
                                    filterEndDate={filterEndDate}
                                    showDateRangePickerFieldInHeading={true}
                                />
                            ))}
                    </div>
                </div>
            </div>

            {/* START  DIALOG MODAL */}

            <Modal show={showMessageModal} onHide={closeMessageModal}>
                <Modal.Header className="px-5 py-4" closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <p>{showMessages}</p>
                    <div className="text-end mt-4">
                        <Button
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            variant="secondary"
                            onClick={closeMessageModal}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* END  DIALOG MODAL */}
        </>
    );
};

export default EventEnquiry;
