import React, { useState } from "react";
import Table from "../../components/Table2";
import { getContectQueryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../globalContext";
import { useContext } from "react";
import CSVFileDownload from "../../components/CSVFileDownload";

const Message = () => {
  const { bwebsiteId } = useContext(GlobalContext);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMessages, setMessages] = useState("");

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  const websiteIdFromCookie = getCookie("websiteId");
  const websiteNameFromCookie = getCookie("websiteName");
  
  const allEnqueryData = useQuery(
    ["getContectQueryData", +websiteIdFromCookie],
    () => getContectQueryData(websiteIdFromCookie)
  );

  const handleViewMessageModal = (message) => {
    setMessages(message);

    setShowMessageModal(true);
  };
  const closeMessageModal = () => setShowMessageModal(false);

  // let currentDateTime = new Date();
  // const currentDate = currentDateTime.getDate();
  // const nextDate = new Date(currentDateTime.setDate(currentDate + 1))
  // const [filterStartDate, setStart] = useState(new Date());
  // const [filterEndDate, setEnd] = useState(nextDate);
 
  const currentDateTime = new Date();
  const [filterStartDate, setStart] = useState(null); // Initially no date selected
  const [filterEndDate, setEnd] = useState(null);

  // Filter data based on date range
  const filteredData =
  filterStartDate && filterEndDate
    ? allEnqueryData?.data?.data?.filter((item) => {
        const itemDate = new Date(item.datetime);
        return itemDate >= filterStartDate && itemDate <= filterEndDate;
      })
    : allEnqueryData?.data?.data; // No filter applied, show all data
  const ExcelFileDownloadData = filteredData || allEnqueryData?.data?.data;

  console.log("allEventEnqueryData", allEnqueryData)

  const [excelDownload, setDownload] = useState();

  const handleDownloadExcelFile = (downloadExcel) => {
    setDownload(downloadExcel);
  };

  let columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },

      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => `${row.original.name}`,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => `${row.original.email}`,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ row }) => `${row.original.phone}`,
      },
      {
        Header: "Resort",
        accessor: `${bwebsiteId === "AHR"? "website":"resort_name"}`,
        Cell: ({ row }) => `${bwebsiteId === "AHR" ? (row.original.website || "") : (row.original.resort_name||"")}`,
      },
      {
        Header: "Enquiry Type",
        accessor: "enquiry_type",
        Cell: ({ row }) => `${row.original.enquiry_type}`,
      },
      {
        Header: "DateTime",
        accessor: "datetime",
        Cell: ({ row }) =>
          moment(row.original.datetime).format("MMM D, YYYY h:mm A [GMT]Z"),
      },

      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ row }) => (
          // `${row.original.subject}`
          <>
            {row?.original?.subject?.length > 20
              ? `${row?.original?.subject?.substring(0, 20)}...`
              : row?.original?.subject}
            {row?.original?.subject?.length > 20 ? (
              <button
                variant="info"
                onClick={() => handleViewMessageModal(row?.original?.subject)}
                className="btn text-success fw-semibold text-uppercase pt-0 pb-0 ms-1"
              >
                View
              </button>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ row }) => (
          <>
            {row?.original?.message?.length > 20
              ? `${row?.original?.message?.substring(0, 20)}...`
              : row?.original?.message}
            {row?.original?.message?.length > 20 ? (
              <button
                variant="info"
                onClick={() => handleViewMessageModal(row?.original?.message)}
                className="btn text-success fw-semibold text-uppercase pt-0 pb-0 ms-1"
              >
                View
              </button>
            ) : (
              ""
            )}
          </>
        ),
      },
    ],
    []
  );
  if (bwebsiteId === "AHR") {
    columns = columns.filter((item) =>
      item?.Header !== "Subject"
    )
  }

  const decodedName = decodeURIComponent(websiteNameFromCookie); // Decodes the string
  const websiteName = decodedName.replace(/\s+/g, ' '); // Removes spaces
 const getFileName = () => {
        if (filterStartDate && filterEndDate) {
            // If filtered, use the start and end date
            return `${websiteName} Enquiries_${moment(filterStartDate).format("DD/MM/YYYY")} - ${moment(filterEndDate).format("DD/MM/YYYY")}.xlsx`;
        } else if (allEnqueryData?.data?.data?.length) {
            // If not filtered, use the first and last created_on dates from the data
            const firstDate = allEnqueryData?.data?.data[0]?.datetime;
            const lastDate = allEnqueryData?.data?.data[allEnqueryData?.data?.data?.length - 1]?.datetime;

            return `${websiteName} Enquiries_${firstDate?moment(firstDate).format("DD/MM/YYYY"):""} - ${lastDate?moment(lastDate).format("DD/MM/YYYY"):"25_11_2024"}.xlsx`;
        }
        return `${websiteName} Enquiries.xlsx`; // Fallback in case no data is available
    };
   
 

 
  const headerHeading = "All Enquiries";
  let headersForExcelFile = [
    "Sr. No",
    "Name",
    "Email",
    "Phone",
    "Enquiry Type",
    "Message",
    "Created On",
  ];  
  // Exclude "Subject" if bwebsiteId is "AHR"
  if (bwebsiteId !== "AHR") {
    headersForExcelFile.splice(5, 0, "Subject"); // Insert "Subject" before "Message"
  }
  
  const excelFileData = ExcelFileDownloadData?.map((item, index) => {
    const row = [
      index + 1, // Sr. No
      item.name, // Name
      item.email, // Email
      item.phone, // Phone
      item.enquiry_type, // Enquiry Type
    ];  
    // Include Subject only if bwebsiteId is not "AHR"
    if (bwebsiteId !== "AHR") {
      row.push(item.subject || ""); // Subject
    }  
    row.push(
      item.message, // Message
      item.datetime?moment(item.datetime).format("DD-MM-YYYY HH:mm:ss"):"" // Datetime
    );  
    return row;
  });


  if (allEnqueryData.status === "loading") {
    <div>Loading</div>;
  }

  if (!allEnqueryData.data) {
    <div>Loading</div>;
  }
  if (allEnqueryData.isLoading) {
    <div>Loading</div>;
  }
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Enquiries</h4>
            </div>
          </div>

          <div className="card mt-lg-5 mt-4">
            {/* <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All Enquiries</h5>
                        </div> */}
            <div className="bg-transparent d-flex align-items-center justify-content-between">
             
              {allEnqueryData.data && (
                <CSVFileDownload
                  // data={downloadData?.map((item, index) => [index + 1, item.name,item.email ,item.phone,item.enquiry_type,`${bwebsiteId==="AHR"?"":item.subject}`,item.message, moment(item.datetime).format(
                  //   "DD-MM-YYYY HH:mm:ss"
                  // )])}
                  data={excelFileData}
                  headers={headersForExcelFile}
                  filename={getFileName()}
                  excelDownload={excelDownload}
                  onClick={handleDownloadExcelFile}
                />
              )}
            </div>
            {allEnqueryData.data &&
              (allEnqueryData.isLoading || !allEnqueryData?.data ? (
                <div className="text-center">Loading...</div>
              ) : allEnqueryData?.data?.data?.length === 0 ? (
                <div className="card-body text-center">No Data Available</div>
              ) : (
                <Table
                  data={ExcelFileDownloadData}
                  key={allEnqueryData?.data?.data?.enquiry_id}
                  columns={columns}
                  isShowingHeading={true}
                  isExcelDownloadBtnShow={true}
                  handleDownloadExcelFile={handleDownloadExcelFile} 
                  headerHeading={headerHeading}
                  setStart={setStart}
                  setEndDate={setEnd}
                  filterStartDate={filterStartDate}
                  filterEndDate={filterEndDate}
                  showDateRangePickerFieldInHeading={true}
                />
              ))}
          </div>
        </div>
      </div>

      {/* START  DIALOG MODAL */}

      <Modal show={showMessageModal} onHide={closeMessageModal}>
        <Modal.Header className="px-5 py-4" closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">
          <p>{showMessages}</p>
          <div className="text-end mt-4">
            <Button
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
              variant="secondary"
              onClick={closeMessageModal}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* END  DIALOG MODAL */}
    </>
  );
};

export default Message;
